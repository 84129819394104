import { FormSelectOption } from '@/types/form'

import { ContextType } from '@/plugins/context/context.enum'
import i18n from '@/plugins/i18n'

export const PUBLISHER_REPORTS_SCHEMA = 'PublishersHourlyKpis'
export const PUBLISHER_REPORTS_SCHEMA_V1 = 'AdagioReportingV1'
export const PUBLISHER_DIRECT_SEAT_REPORTS_SCHEMA = 'PublishersDailySeatKpisEnriched'

export const BIDDER_REPORTS_SCHEMA = 'PsmDailyKpis2'

export type CubeSchemasItem = {
  title: string
  readonly?: boolean
  blacklisted: Record<'dimensions' | 'metrics', string[]>
  deprecated: Record<'dimensions' | 'metrics', string[]>
  order?: Record<'dimensions', string[]>
}

export type CubeSchemas = Record<ContextType, Record<string, CubeSchemasItem >>

// Note:
// `title` property could be removed as we can set the title directly in Schema definition (ssp-cube project).
// It has been kept here in order to allow translations.
export const cubeSchemas = (): CubeSchemas => ({
  [ContextType.GROUPS]: {},
  [ContextType.PUBLISHERS]: {
    [PUBLISHER_REPORTS_SCHEMA]: {
      title: i18n.global.t(`cube.schemas.${PUBLISHER_REPORTS_SCHEMA}`),
      blacklisted: {
        dimensions: [
          'organizationId',
          'partitionTime',
          'windowStart',
          'inventoryPublisherSellersJsonReason',
          'inventoryPublisherSellerType',
          'inventoryPublisherId',
          'inventoryPublisherName',
          'inventoryWebsiteAdsTxtReason',
          'inventoryWebsiteStatus'
        ],
        metrics: [
          'none',
          'notViewed',
          'bidRate',
          'winRate',
          'fillRate',
          'rpb',
          'vRate',
          'timeInView',
          'adresponsesCPM',
          'cpm'
        ]
      },
      deprecated: {
        dimensions: [],
        metrics: []
      },
      order: {
        dimensions: [
          'day',
          'hour',
          'platform',
          'integrationType',
          'site',
          'domain',
          'bundle',
          'device',
          'pagetype',
          'placement'
        ]
      }
    },
    [PUBLISHER_DIRECT_SEAT_REPORTS_SCHEMA]: {
      title: i18n.global.t(`cube.schemas.${PUBLISHER_DIRECT_SEAT_REPORTS_SCHEMA}`),
      blacklisted: {
        dimensions: [
          'inventoryPublisherId',
          'partitionTime'
        ],
        metrics: [
          'none'
        ]
      },
      deprecated: {
        dimensions: [],
        metrics: []
      }
    },
    [PUBLISHER_REPORTS_SCHEMA_V1]: {
      title: i18n.global.t(`cube.schemas.${PUBLISHER_REPORTS_SCHEMA_V1}`),
      readonly: true,
      blacklisted: {
        dimensions: [
          'partitionTime'
        ],
        metrics: [
          'none'
        ]
      },
      deprecated: {
        dimensions: [
          'publisher',
          'subcategory',
          'environment'
        ],
        metrics: []
      }
    }
  },
  [ContextType.BIDDERS]: {
    [BIDDER_REPORTS_SCHEMA]: {
      title: i18n.global.t(`cube.schemas.${BIDDER_REPORTS_SCHEMA}`),
      blacklisted: {
        dimensions: [
          'partitionTime'
        ],
        metrics: [
          'none'
        ]
      },
      deprecated: {
        dimensions: [],
        metrics: []
      }
    }
  }
})

export function periodOptions (): Array<FormSelectOption<string>> {
  return [
    { id: 'yesterday', name: i18n.global.t('calendar.pastDay') },
    { id: 'last_7_days', name: i18n.global.t('calendar.last7Days') },
    { id: 'past_week', name: i18n.global.t('calendar.pastWeek') },
    { id: 'past_month', name: i18n.global.t('calendar.pastMonth') },
    { id: 'rolling_week', name: i18n.global.t('calendar.rollingWeek') },
    { id: 'rolling_month', name: i18n.global.t('calendar.rollingMonth') }
  ]
}

export function dayOfExportWeekOptions (): Array<FormSelectOption<number>> {
  return [
    { id: 1, name: i18n.global.t('calendar.monday') },
    { id: 2, name: i18n.global.t('calendar.tuesday') },
    { id: 3, name: i18n.global.t('calendar.wednesday') },
    { id: 4, name: i18n.global.t('calendar.thursday') },
    { id: 5, name: i18n.global.t('calendar.friday') },
    { id: 6, name: i18n.global.t('calendar.saturday') },
    { id: 7, name: i18n.global.t('calendar.sunday') }
  ]
}

export function dayOfExportMonthOptions (): Array<FormSelectOption<number>> {
  const options = []
  for (let i = 1; i < 29; i++) {
    options.push({ id: i, name: `${i}` })
  }
  return options
}
